import ScrollToTop from "../components/ScrollToTop";

export default function About () {
    return (
        <section>
          <ScrollToTop/>
      {/* Container */}
      <div className="mx-auto w-full max-w-7xl px-5 py-16 md:px-10 md:py-20">
        {/* Title */}
        <h2 className="mb-8 text-3xl font-bold md:text-5xl lg:mb-14">
          Ark Platforms, Inc
        </h2>
        <p className="mb-8 text-sm text-gray-500 sm:text-base lg:mb-24">
        At Ark Platforms, Inc., we are dedicated to driving innovation through technology and design. Based in Bogotá, Colombia, our company is proudly registered with the Chamber of Commerce, reflecting our commitment to excellence and transparency. Specializing in cutting-edge solutions, we focus on delivering high-quality software, 3D design, and digital experiences that empower businesses to thrive in a rapidly evolving market. Our team of experts is passionate about turning your vision into reality, providing personalized services that cater to your unique needs.

        </p>
        <div className="grid gap-10 lg:grid-cols-2 lg:gap-12">
          <img
            src="images/business.webp"
            alt=""
            className="inline-block h-full w-full rounded-2xl object-cover"
          />
          <div className="flex flex-col gap-5 rounded-2xl border border-gray-300 / 80 p-4 sm:p-20">
            <h2 className="text-3xl font-bold md:text-5xl">Our Mission</h2>
            <p className="text-sm text-gray-500 sm:text-base">
            At Ark Platforms, Inc., our mission is to revolutionize the way businesses interact with technology. We strive to deliver innovative software solutions and stunning 3D designs that drive growth, enhance user experiences, and elevate brand presence. Committed to excellence and customer satisfaction, we work tirelessly to provide tailored, cutting-edge solutions that meet the unique needs of each client. Our goal is to empower businesses with the tools and insights they need to succeed in a dynamic digital landscape.
            </p>
          </div>
        </div>
      </div>
    </section>
    )
}