import { useState } from "react";
import ScrollToTop from "../components/ScrollToTop";

export default function Contact () {
    const [checked, setChecked] = useState(false);

  const handleChange = () => {
    setChecked(!checked);
  };
  return (
    <section>
      <ScrollToTop/>
      {/* Container */}
      <div className="mx-auto max-w-7xl px-5 py-16 md:px-10 md:py-20">
        {/* Component */}
        <h2 className="text-3xl font-semibold md:text-5xl text-indigo-950">Contact Us</h2>
        <p className=" mb-8 mt-4 max-w-lg text-gray-500 md:mb-12 lg:mb-16">
        If you have problems, any questions or want to know something that you have not been able to find, complete this form and we will contact you within 24 to 48 hours
        </p>
        {/* Form */}
        <form
          name="wf-form-name"
          method="get"
          className="mx-auto mb-4 text-left "
        >
          <div className="mb-4 grid w-full grid-cols-2 gap-6">
            <div>
              <label htmlFor="name-2" className="mb-1 font-medium">
                First Name
              </label>
              <input
                type="text"
                className="w-full border my-3 py-4 border-gray-300 /80 text-black px-3 focus:outline-none focus:ring-0 rounded-xl"
                placeholder=""
                required=""
              />
            </div>
            <div>
              <label htmlFor="name-3" className="mb-1 font-medium">
                Last Name
              </label>
              <input
                type="text"
                className="w-full border my-3 py-4 border-gray-300 /80 text-black px-3 focus:outline-none focus:ring-0 rounded-xl"
                placeholder=""
                required=""
              />
            </div>
          </div>
          <div className="mb-4">
            <label htmlFor="field" className="mb-1 font-medium">
              Company Website
            </label>
            <input
              type="text"
              className="w-full border my-3 py-4 border-gray-300 /80 text-black px-3 focus:outline-none focus:ring-0 rounded-xl"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="field" className="mb-1 font-medium">
              Email
            </label>
            <input
              type="text"
              className="w-full border my-3 py-4 border-gray-300 /80 text-black px-3 focus:outline-none focus:ring-0 rounded-xl"
            />
          </div>
          <div className="mb-5 md:mb-6 lg:mb-8">
            <label htmlFor="field" className="mb-1 font-medium">
              Message
            </label>
            <textarea
              placeholder=""
              maxLength="5000"
              name="field"
              className="w-full border my-3 py-4 border-gray-300 /80 text-black px-3 focus:outline-none focus:ring-0 rounded-xl"
            ></textarea>
          </div>
          <label className="flex items-start cursor-pointer mb-1 justify-start pb-4">
            {/* Toggle */}
            <div
              className={`relative w-12 h-7 transition duration-200 ease-linear rounded-full ${
                checked ? "bg-blue-600" : "bg-gray-100"
              }`}
            >
              {/* Switch */}
              <span
                className={`absolute left-1 top-1 transition duration-200 ease-in-out transform bg-white rounded-full w-5 h-5 ${
                  checked ? "translate-x-5" : ""
                }`}
              />
            </div>
            <input
              type="checkbox"
              className="hidden"
              checked={checked}
              onChange={handleChange}
            />
            <span
              className="ml-4 inline-block cursor-pointer text-sm max-w-48 md:max-w-full"
              htmlFor="checkbox-2"
            >
              By selecting this, you agree to the{" "}
              <a href="#"> Privacy Policy</a> and <a href="#">Cookie Policy</a>
            </span>
          </label>

          <input
            type="submit"
            value="Send"
            className="inline-block w-full rounded-md cursor-pointer bg-black px-6 py-3 text-center font-semibold text-white"
          />
        </form>
      </div>
    </section>
  );
}