// App.js
import "preline/preline";
import DefaultLayout from "./layout/Layout";
import AppRoutes from "./routes/routes";
import { ThemeProvider } from "./components/ThemeContext";
import ThemeToggleButton from "./components/ThemeToggleButton";
import './App.css';

function App() {
  return (
    <ThemeProvider>
      <DefaultLayout>
        <AppRoutes />
      </DefaultLayout>
    </ThemeProvider>
  );
}

export default App;
