import React from 'react';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';
import ScrollToTop from '../components/ScrollToTop';
import ThemeToggleButton from '../components/ThemeToggleButton';

const DefaultLayout = ({ children }) => {
  return (
    <div className="">
      <ScrollToTop children={children} /> {/* Pasa el children como prop */}
      <Navbar />
      <main >
        <div className="mx-auto max-w-screen dark:bg-black">
          {children}
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default DefaultLayout;
