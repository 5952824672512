import { ArrowRight } from 'lucide-react';
import React, { Component } from 'react';

export default function Hire() {
    return (
        <div>
            <section>
                {/* Container */}
                <div className="mx-auto w-full max-w-7xl px-5 py-16 md:px-10 md:py-20">
                    {/* Component */}
                    <div className="grid items-center gap-8 sm:gap-20 lg:grid-cols-2">
                        <div className="max-w-3xl">
                            {/* Title */}
                            <h2 className="mb-2 text-3xl font-bold md:text-5xl text-indigo-950">
                                Let's build something exciting together!
                            </h2>
                            <p className="my-4 max-w-lg pb-4 text-sm text-gray-500 sm:text-base md:mb-6 lg:mb-8">
                            Partner with us to transform your vision into reality. Our dedicated team of experts is ready to collaborate, innovate, and deliver exceptional solutions tailored to your specific needs. Whether you're looking to develop a new product, enhance your current offerings, or explore new opportunities, we’re here to make your project a success. Let's create something remarkable together!
                            </p>
                            {/* Testimonial */}
                            <div className="mb-4 flex items-center text-orange-500">
                                <svg
                                    className="h-8 w-8"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="100%"
                                    height="100%"
                                    preserveAspectRatio="xMidYMid meet"
                                    viewBox="0 0 24 24"
                                >
                                    <path
                                        fill="currentColor"
                                        d="m12 17.27l4.15 2.51c.76.46 1.69-.22 1.49-1.08l-1.1-4.72l3.67-3.18c.67-.58.31-1.68-.57-1.75l-4.83-.41l-1.89-4.46c-.34-.81-1.5-.81-1.84 0L9.19 8.63l-4.83.41c-.88.07-1.24 1.17-.57 1.75l3.67 3.18l-1.1 4.72c-.2.86.73 1.54 1.49 1.08l4.15-2.5z"
                                    ></path>
                                </svg>
                                <svg
                                    className="h-8 w-8"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="100%"
                                    height="100%"
                                    preserveAspectRatio="xMidYMid meet"
                                    viewBox="0 0 24 24"
                                >
                                    <path
                                        fill="currentColor"
                                        d="m12 17.27l4.15 2.51c.76.46 1.69-.22 1.49-1.08l-1.1-4.72l3.67-3.18c.67-.58.31-1.68-.57-1.75l-4.83-.41l-1.89-4.46c-.34-.81-1.5-.81-1.84 0L9.19 8.63l-4.83.41c-.88.07-1.24 1.17-.57 1.75l3.67 3.18l-1.1 4.72c-.2.86.73 1.54 1.49 1.08l4.15-2.5z"
                                    ></path>
                                </svg>
                                <svg
                                    className="h-8 w-8"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="100%"
                                    height="100%"
                                    preserveAspectRatio="xMidYMid meet"
                                    viewBox="0 0 24 24"
                                >
                                    <path
                                        fill="currentColor"
                                        d="m12 17.27l4.15 2.51c.76.46 1.69-.22 1.49-1.08l-1.1-4.72l3.67-3.18c.67-.58.31-1.68-.57-1.75l-4.83-.41l-1.89-4.46c-.34-.81-1.5-.81-1.84 0L9.19 8.63l-4.83.41c-.88.07-1.24 1.17-.57 1.75l3.67 3.18l-1.1 4.72c-.2.86.73 1.54 1.49 1.08l4.15-2.5z"
                                    ></path>
                                </svg>
                                <svg
                                    className="h-8 w-8"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="100%"
                                    height="100%"
                                    preserveAspectRatio="xMidYMid meet"
                                    viewBox="0 0 24 24"
                                >
                                    <path
                                        fill="currentColor"
                                        d="m12 17.27l4.15 2.51c.76.46 1.69-.22 1.49-1.08l-1.1-4.72l3.67-3.18c.67-.58.31-1.68-.57-1.75l-4.83-.41l-1.89-4.46c-.34-.81-1.5-.81-1.84 0L9.19 8.63l-4.83.41c-.88.07-1.24 1.17-.57 1.75l3.67 3.18l-1.1 4.72c-.2.86.73 1.54 1.49 1.08l4.15-2.5z"
                                    ></path>
                                </svg>
                                <svg
                                    className="h-8 w-8"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="100%"
                                    height="100%"
                                    preserveAspectRatio="xMidYMid meet"
                                    viewBox="0 0 24 24"
                                >
                                    <path
                                        fill="currentColor"
                                        d="m12 17.27l4.15 2.51c.76.46 1.69-.22 1.49-1.08l-1.1-4.72l3.67-3.18c.67-.58.31-1.68-.57-1.75l-4.83-.41l-1.89-4.46c-.34-.81-1.5-.81-1.84 0L9.19 8.63l-4.83.41c-.88.07-1.24 1.17-.57 1.75l3.67 3.18l-1.1 4.72c-.2.86.73 1.54 1.49 1.08l4.15-2.5z"
                                    ></path>
                                </svg>
                            </div>
                            <p className="mb-8 max-w-lg text-sm text-gray-500 sm:text-base">
                            I am excited about the possibility of collaborating with you. With my experience in web development and design, I am committed to offering innovative solutions that grow your business. If you are looking for a reliable partner for your next project, contact me and let's build something amazing together.
                            </p>
                            <div className="flex">
                                <img
                                loading={'lazy'}
                                    src="https://scontent-mad2-1.xx.fbcdn.net/v/t39.30808-6/410878264_882343330174146_1700003784147278395_n.jpg?_nc_cat=108&ccb=1-7&_nc_sid=a5f93a&_nc_ohc=hIsR8a8CaU4Q7kNvgEvekgE&_nc_ht=scontent-mad2-1.xx&_nc_gid=AtJ0MF2fQQd2PXTScHCj6Rl&oh=00_AYBikxXKK4mn5vQZsztzkR8T_SFGk6EI1I0mS_Svv7WFFw&oe=66EFB788"
                                    alt="Pablo Cuevas"
                                    width={0}
                                    height={0}
                                    className="mr-4 inline-block h-16 w-16 rounded-full object-cover"
                                />
                                <div className="flex flex-col">
                                    <h6 className="text-base font-bold">Pablo Cuevas</h6>
                                    <p className="text-sm text-gray-500">CEO, Full Stack Developer & Designer </p>
                                </div>
                            </div>
                        </div>
                        <div className="mx-auto max-w-xl bg-gray-100 p-8 rounded-3xl text-start">
                            <h3 className="text-2xl font-bold md:text-3xl text-indigo-950">Get a free quote</h3>
                            <p className="mx-auto mb-6 mt-4 max-w-lg text-sm text-gray-500 lg:mb-8">
                            Discover how we can transform your project with a free consultation. Simply send us the details and we will provide you with a personalized, no-obligation estimate.
                            </p>
                            {/* Form */}
                            <form
                                className=" mb-4 text-start"
                                name="wf-form-password"
                                method="get"
                            >
                                <div>
                                    <label htmlFor="name-2" className="mb-1 font-medium text-indigo-950">
                                        Your Name
                                    </label>
                                    <input
                                        type="text"
                                        className="mb-4 block h-9 w-full rounded-md border border-solid mt-2 border-gray-300 /80 px-3 py-6 pl-4 text-sm text-black focus:outline-none focus:right-0"
                                    />
                                </div>
                                <div className="mb-2">
                                    <label htmlFor="name-2" className="mb-1 font-medium text-indigo-950">
                                        Email Address
                                    </label>
                                    <input
                                        type="text"
                                        className="mb-4 block h-9 w-full rounded-md border border-solid mt-2 border-gray-300 /80 px-3 py-6 pl-4 text-sm text-black focus:outline-none focus:right-0"
                                    />
                                </div>
                                <div className="mb-2">
                                    <label htmlFor="name-2" className="mb-1 font-medium text-indigo-950">
                                        Project Type
                                    </label>
                                    <select name="" id="" className="mb-4 block h-9 w-full rounded-md border border-solid mt-2 border-gray-300 /80 px-3 py-6 pl-4 text-sm text-black focus:outline-none focus:right-0">
                                        <option selected disabled hidden>{''}</option>
                                        <option value="App">Mobile Aplication</option>
                                        <option value="Software">Computer software</option>
                                        <option value="Web">Web Site</option>
                                        <option value="Design">3D Design</option>
                                    </select>
                                </div>
                                <div className="mb-5 md:mb-6 lg:mb-8 w-full">
                                    <label htmlFor="field-3" className="mb-1 font-medium text-indigo-950">
                                        Project Brief
                                    </label>
                                    <textarea
                                        placeholder=""
                                        maxLength="5000"
                                        name="field"
                                        className="mb-4 block h-28 resize-none w-full rounded-md border border-solid mt-2 border-gray-300 /80 px-3 py-6 pl-4 text-sm text-black focus:outline-none focus:right-0"
                                    ></textarea>
                                </div>
                                <button type="submit" className="w-full flex flex-row justify-between items-center cursor-pointer rounded-md bg-black px-6 py-4 text-start font-semibold text-white">
                                    <span>
                                    Get free quote
                                    </span>
                                    <ArrowRight className='size-4'/>
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}
