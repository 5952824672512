import {
    Globe,
    Layout,
    Mail,
    CheckCircle,
    BarChart,
    ShoppingBag,
} from 'lucide-react'
import { useState } from 'react';
import CallBack from '../components/CallBack';

export default function Web() {

    const [openFAQ, setOpenFAQ] = useState(null);


    // Function to toggle the visibility of FAQ details
    const toggleFAQ = (index) => {
        setOpenFAQ(openFAQ === index ? null : index);
    };


    // Dummy data for FAQs
    const faqs = [
        {
            title: "How long does it take to develop a website?",
            content:
                "The timeline depends on the complexity of the website, its design, and the features you require. A basic website may take a few weeks, while a more complex site with custom features can take several months to complete, including testing and launch.",
        },
        {
            title: "What is the cost of developing a website?",
            content:
                "The cost varies depending on the design, features, and platform you need. Whether it's a simple informational website or a full-scale e-commerce platform, we provide tailored estimates based on your requirements.",
        },
        {
            title: "Will I be able to update the website after it’s launched?",
            content:
                "Yes, we offer content management systems (CMS) that allow you to easily update your website. Additionally, we provide ongoing support and maintenance for any future updates or improvements.",
        },
        {
            title: "Can my website be integrated with other services and tools?",
            content:
                "Absolutely. We can integrate your website with third-party services, such as payment gateways, marketing tools, and analytics platforms, to enhance functionality and improve the user experience.",
        },
    ];
    

    const feacture = [
        {
            id: 1,
            title: 'Enhanced Online Visibility',
            description: "A website allows your business to be easily found online, making it accessible to customers 24/7 from anywhere.",
            icon: Globe
        },
        {
            id: 2,
            title: 'Improved User Experience',
            description: "Websites can provide a smooth and optimized browsing experience, ensuring users can navigate effortlessly and find what they need.",
            icon: Layout
        },
        {
            id: 3,
            title: 'Direct Communication with Customers',
            description: "Through contact forms, live chat, or newsletters, a website allows you to communicate directly with your audience.",
            icon: Mail
        },
        {
            id: 4,
            title: 'Increased Brand Credibility',
            description: "A professionally designed website builds trust and credibility, showcasing your business’s legitimacy and authority in the market.",
            icon: CheckCircle
        },
        {
            id: 5,
            title: 'Valuable Analytics and Data',
            description: "Websites can track user interactions, providing insights that allow you to optimize your marketing strategies and business decisions.",
            icon: BarChart
        },
        {
            id: 6,
            title: 'Boosted Sales and Lead Generation',
            description: "A website serves as a direct platform for generating leads and increasing sales through e-commerce and service inquiries.",
            icon: ShoppingBag
        }
    ];
    

    return (
        <main>
            <div className="mx-auto w-full max-w-7xl px-5 py-16 md:px-10 md:py-20">
                {/* Component */}
                <div className="grid grid-cols-1 items-center gap-8 sm:gap-20 lg:grid-cols-2">
                    {/* Heading Div */}
                    <div className="max-w-3xl">
                        <h1 className="mb-6 pb-4 text-4xl font-bold md:text-6xl">
                        Why develop a website for your business?
                        </h1>
                        <p className="mb-6 max-w-lg text-gray-500 md:mb-10 lg:mb-12">
                        A website serves as the digital storefront of your business, providing a platform to showcase your products or services, reach a global audience, and build credibility. It’s essential for staying competitive, enhancing customer engagement, and driving growth in today’s digital age.
                        </p>
                        <a
                            href={'/web#call-us'}
                            className="mb-5 mr-5 inline-block rounded-lg bg-black px-6 py-4 text-center font-bold text-white transitionss lg:mb-8 lg:mr-8"
                        >
                            Call Me
                        </a>
                    </div>
                    {/* Image Div */}
                    <div>
                        <img
                            src="images/webs.webp"
                            alt=""
                            className="mx-auto inline-block rounded-3xl h-full w-full max-w-2xl object-cover"
                        />
                    </div>
                </div>
            </div>
            <section>
                {/* Container */}
                <div className="mx-auto w-full max-w-7xl px-5 py-16 md:px-10 md:py-20">
                    {/* Title */}
                    <div className="flex flex-col items-center text-center">
                        <h2 className="text-3xl font-bold md:text-5xl">
                            Key Benefits of Developing a Website
                        </h2>
                        <p className="mb-8 mt-4 max-w-xl text-base text-gray-500 md:mb-12 md:text-lg lg:mb-16">
                        A well-designed website helps your business gain visibility, build trust with customers, and expand its reach. It enhances user experience, boosts sales, and provides valuable insights into customer behavior, all while establishing a strong online presence.
                        </p>
                    </div>
                    {/* Features Content */}
                    <div className="grid gap-5 sm:grid-cols-2 md:grid-cols-3 md:gap-4 lg:gap-6">
                        {/* Features Item */}
                        {
                            feacture.map(feactures => {
                                const IconComponent = feactures.icon;
                                return (
                                    <div key={feactures.id} className="grid gap-6 rounded-3xl border border-solid border-gray-300 / 80 p-8 md:p-10">
                                        <div className="w-16 h-16 bg-gray-100 rounded-full flex justify-center items-center">
                                            <IconComponent className="w-8 h-8 text-black" />
                                        </div>
                                        <h3 className="text-xl font-semibold">{feactures.title}</h3>
                                        <p className="text-sm text-gray-500">
                                            {feactures.description}
                                        </p>
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            </section>
            <section>
                <div className="py-16 md:py-20 mx-auto w-full max-w-7xl px-5 md:px-10">
                    <div className="flex flex-col items-start lg:flex-row lg:space-x-20">
                        <div className="lg:flex-[1_1_500px] w-full flex-none">
                            <div className="max-w-3xl mb-8 md:mb-12 lg:mb-16">
                                <h2 className="font-bold text-3xl md:text-5xl">General FAQs</h2>
                                <div className="mt-4 max-w-lg">
                                    <p className="text-gray-500 text-sm sm:text-base">
                                        Below are some of the most frequently asked questions by our clients, along with their answers, to help you better understand the app development process
                                    </p>
                                </div>
                            </div>
                            <div className="mb-6 h-full w-full overflow-auto bg-gray-100 p-8 rounded-3xl">
                                <div className="flex flex-row gap-4">
                                    <img
                                        src="images/user.png"
                                        alt="Placeholder"
                                        className="inline-block h-12 w-12 object-cover rounded-full"
                                    />
                                    <div className="flex flex-col gap-1.5">
                                        <h5 className="text-xl font-bold">Still have questions?</h5>
                                        <div className="max-w-sm">
                                            <p className="text-gray-500 text-sm sm:text-base">
                                                Can’t find the answer you’re looking for? Please chat to
                                                our support.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-6 mt-8 h-[0.5px] w-full bg-gray-300"></div>
                                <a
                                    href={'/web#call-us'}
                                    className="inline-block items-center rounded-md bg-black px-6 py-3 text-center font-semibold text-white"
                                >
                                    Call me
                                </a>
                            </div>
                        </div>
                        <div className="lg:flex-[1_1_500px] w-full flex-none">
                            {faqs.map((faq, index) => (
                                <div
                                    key={index}
                                    className="mb-6 w-full overflow-hidden bg-gray-100 p-8 rounded-3xl"
                                >
                                    <div
                                        className="flex cursor-pointer items-start justify-between"
                                        onClick={() => toggleFAQ(index)}
                                    >
                                        <p className="text-lg font-semibold">{faq.title}</p>
                                        <div className="relative ml-10 mt-1 flex h-5 w-5 items-center justify-center">
                                            <div
                                                className={`absolute h-5 w-0.5 bg-black transition-transform duration-300 ${openFAQ === index ? "rotate-90" : ""}`}
                                            ></div>

                                            <div className="h-0.5 w-5 bg-black"></div>
                                        </div>
                                    </div>
                                    {openFAQ === index && (
                                        <div className="w-full overflow-hidden mb-4 max-w-2xl lg:max-w-4xl">
                                            <p className="text-sm sm:text-base">{faq.content}</p>
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </section>
            <CallBack/>
        </main>
    )
}